<template>
    <!-- WARNING this file is generated edits made will be lost on next generation -->
    <div>
        <NavBar></NavBar>
        <div class="container-fluid">
            <b-row>
            <!-- Sidebar -->
            <TOCChapter chapter-id="Chap41DesignRecommendations"></TOCChapter>
				<b-col ></b-col>
                <!-- Main Content -->
                <b-col role="main" md="6" >
					<ChapterHeading chapter-title="Chapter 41: Design Recommendations" image-name="design.jpg" image-alt="Design Recommendations"></ChapterHeading>
                    <!-- Contents BEGIN -->
                    <div id="what-will-you-learn-in-this-chapter" class="anchor"></div>
<h1 data-number="1"><span class="header-section-number">1</span> What will you learn in this chapter? <a href="#what-will-you-learn-in-this-chapter"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<ul>
<li>We will detail some practical design advice to improve your code.</li>
</ul>
<div id="technical-concepts-covered" class="anchor"></div>
<h1 data-number="2"><span class="header-section-number">2</span> Technical concepts covered <a href="#technical-concepts-covered"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<ul>
<li><p>package</p></li>
<li><p>interface</p></li>
<li><p>method</p></li>
<li><p>receiver</p></li>
<li><p>cyclomatic complexity</p></li>
<li><p>Halstead metrics</p></li>
</ul>
<h1 class="unnumbered" id="introduction">Introduction</h1>
<p>This chapter will try to answer the question “how do I design my Go code?”. As a new Go developer from other languages, I asked myself this question during my beginnings. When you write software for your only use, this question is not important. When you work in a team, you have to follow conventions and best practices.</p>
<p>I have read popular blog posts written by Go developers to write this chapter. My objective in this chapter is to compile those bits of advice. Do not follow them religiously. Keep in mind that each project is different.</p>
<div id="package-names" class="anchor"></div>
<BuyCopyInvite></BuyCopyInvite>
<h1 data-number="3"><span class="header-section-number">3</span> Package names <a href="#package-names"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>Packages names are exposed to package users. Therefore they must be chosen with attention by developers. What does it means when I say exposed to users ? It means that when somebody wants to use the function Bar of your package, he has to write :</p>
<pre v-highlightjs><code class="go" v-pre >pkgName.Bar()</code></pre>
<p>Here are some standard rules that we can follow</p>
<p>Sources : <b-link class="citation" data-cites="dave-design" href="#dave-design" >[@dave-design]</b-link></p>
<p><b-link class="citation" data-cites="ds-design" href="#ds-design" >[@ds-design]</b-link></p>
<table>
<caption>Package Names recommendations</caption>
<thead>
<tr class="header">
<th style="text-align: center;">Advice</th>
</tr>
</thead>
<tbody>
<tr class="odd">
<td style="text-align: center;">Short: no more than one word</td>
</tr>
<tr class="even">
<td style="text-align: center;">No plural</td>
</tr>
<tr class="odd">
<td style="text-align: center;">Lower case</td>
</tr>
<tr class="even">
<td style="text-align: center;">Informative about the service it gives</td>
</tr>
<tr class="odd">
<td style="text-align: center;">No utility packages</td>
</tr>
</tbody>
</table>
<ul>
<li><p><u>Examples</u></p>
<ul>
<li><p><strong>net</strong> very short, no plural, all lowercase, immediately we know that this package contains networking functionalities.</p></li>
<li><p><strong>os</strong></p></li>
</ul></li>
<li><p><u>Counterexamples</u></p>
<ul>
<li><p><strong>models</strong>: signals a package that is only here to support type struct that defines a data model. The service it gives is not clear (except that it gather data models). For instance, we could have a user package that will hold the user model and functionalities related to users.</p></li>
<li><p><strong>userManagement</strong> : it’s not a single word. We will use it to manage the users of the application , but in my opinion, those functionalities should live in a “user” package (with methods with a pointer to the User type as receivers).</p></li>
<li><p><strong>utils</strong> : this package generally hold function used in other packages. Therefore it’s advised to move the functions directly in the packages where they are used <b-link class="citation" data-cites="dave-design" href="#dave-design" >[@dave-design]</b-link></p></li>
</ul></li>
<li><p><strong>About utility packages</strong> : having a utils package might seem legit for developers coming from other languages, but for gophers, it does not make sense because it will necessarily mix functions that could have been inserted directly where they are used. It’s some kind of reflex that we have at the beginning of a project to put in this kind of package functions that could be useful elsewhere. But utilities are not prohibited. The standard Go library exposes utility functions but tends to group them by type. For instance <strong>strings</strong> or <strong>bytes</strong>.</p></li>
</ul>
<div id="use-interfaces" class="anchor"></div>
<h1 data-number="4"><span class="header-section-number">4</span> Use interfaces <a href="#use-interfaces"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>Interfaces define behaviors. Based on this definition of behaviors, you can define multiple implementations of behaviors (see <a href="#fig:Interface-and-implementations" data-reference-type="ref" data-reference="fig:Interface-and-implementations">1</a>). Users of your package are not interested in your implementation. They just care about the service you give them. An interface defines a contract for the use of your public API. The implementation might change. For instance, you can improve the performance of the implementation of your method. Even if you change drastically how your package does what it does, the way to call it will remain stable.</p>
<figure>
<b-img :src="require('@/assets/images/interface_implementation.png')" alt="Interface and implementations[fig:Interface-and-implementations]"  fluid thumbnail class="img-book"></b-img><figcaption aria-hidden="true">Interface and implementations<span id="fig:Interface-and-implementations" label="fig:Interface-and-implementations">[fig:Interface-and-implementations]</span></figcaption>
</figure>
<p>With Go, an interface is a special kind of type. You can use it as a function or method argument.</p>
<table>
<caption>Interfaces usage pieces of advice</caption>
<thead>
<tr class="header">
<th style="text-align: center;">Advice</th>
</tr>
</thead>
<tbody>
<tr class="odd">
<td style="text-align: center;">Use interfaces as function/method arguments &amp; as field types</td>
</tr>
<tr class="even">
<td style="text-align: center;">Small interfaces are better</td>
</tr>
</tbody>
</table>
<ul>
<li><strong>Interfaces can be used as arguments in method and functions</strong> (remember that they are also types). By accepting an interface as an argument, you highlight the fact that inside you function, you will only use the behaviors defined by the interface.</li>
</ul>
<p>To better understand, let’s take an example. Imagine building a new fancy cryptographic algorithm to exchange messages with a friend secretly.</p>
<p>You will need to develop a function to decrypt messages (and to encrypt them). In the next listing, you can see your first attempt (<span v-highlightjs><code class="go" v-pre style="display: inline">Decrypt1</code></span>) :</p>
<pre v-highlightjs><code class="go" v-pre >func Decrypt1(b []byte) ([]byte, error) {
    //...
}</code></pre>
<p>It takes a slice of bytes as argument and returns a slice of bytes and an error. There is nothing bad with this function except that we can only use it with a slice of bytes as input. Imagine that instead of a slice of byte, you want to decrypt a whole file with this method? You will need to read all bytes from the file and pass it to the <span v-highlightjs><code class="go" v-pre style="display: inline">Decrypt1</code></span> function.</p>
<p>We have to find a type that will make our <span v-highlightjs><code class="go" v-pre style="display: inline">Decrypt1</code></span> function more generic. The type interface that will serve this purpose is <span v-highlightjs><code class="go" v-pre style="display: inline">io.Reader</code></span>. Many types in the standard library implement this interface:</p>
<ul>
<li><p><span v-highlightjs><code class="go" v-pre style="display: inline">os.File</code></span></p></li>
<li><p><span v-highlightjs><code class="go" v-pre style="display: inline">net.TCPConn</code></span></p></li>
<li><p><span v-highlightjs><code class="go" v-pre style="display: inline">net.UDPConn</code></span></p></li>
<li><p><span v-highlightjs><code class="go" v-pre style="display: inline">net.UnixConn</code></span></p></li>
</ul>
<p>If you accept an <span v-highlightjs><code class="go" v-pre style="display: inline">io.Reader</code></span> as parameter, you can decrypt files, but also use it with data transmitted through TCP or UDP. Here is the second version of the function :</p>
<pre v-highlightjs><code class="go" v-pre >func Decrypt2(r io.Reader) ([]byte, error) {
    //...
}</code></pre>
<p>The <span v-highlightjs><code class="go" v-pre style="display: inline">io.Reader</code></span> interface define one Behaviour, <span v-highlightjs><code class="go" v-pre style="display: inline">Read</code></span>. A type that implements the <span v-highlightjs><code class="go" v-pre style="display: inline">Read</code></span> function as defined in the interface <span v-highlightjs><code class="go" v-pre style="display: inline">io.Reader</code></span> is an <span v-highlightjs><code class="go" v-pre style="display: inline">io.Reader</code></span>.</p>
<p>It means that our <span v-highlightjs><code class="go" v-pre style="display: inline">Decrypt2</code></span> function can take any type that implements the io.Reader interface.</p>
<ul>
<li><strong>Small interfaces are better</strong></li>
</ul>
<p>If we take, for example, the interfaces of the standard Go library, you can notice that they are often very small. The number of behaviors defines the size of an interface (in other words, the number of method signatures specified).</p>
<p>In Go, you do not need to specify that a type implements an interface. Consequently, when your interface is composed of many behaviors, it’s hard to see which types implement the interface. That’s why small interfaces are easier to handle in the day-to-day programmer’s life.</p>
<p>You can note that many interfaces are composed of 2-3 methods in Go standard library. Let’s take the example of the two famous io.Reader and io.Writer :</p>
<pre v-highlightjs><code class="go" v-pre >type Reader interface {
    Read(p []byte) (n int, err error)
}
type Writer interface {
    Write(p []byte) (n int, err error)
}</code></pre>
<p>Here is a counter-example :</p>
<pre v-highlightjs><code class="go" v-pre >type Bad interface {
    Foo(string) error
    Bar(string) error
    Baz([]byte) error
    Bal(string, io.Closer) error
    Cux()
    Corge()
    Corege3()
}</code></pre>
<p>The interface <span v-highlightjs><code class="go" v-pre style="display: inline">Bad</code></span> is hard to implement. Someone that wants to implement it will need to develop seven methods! If you plan to build a widely used package, you make it difficult to newcomers to use your abstractions.</p>
<div id="source-files" class="anchor"></div>
<h1 data-number="5"><span class="header-section-number">5</span> Source files <a href="#source-files"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>A package can be composed of a single file. That’s perfectly legal, but if your file has more than 600 lines<a href="#fn1" class="footnote-ref" id="fnref1" role="doc-noteref"><sup>1</sup></a>, it can become difficult to read. Here are some practical pieces of advice to improve the readability of your sources.</p>
<table>
<caption>Source files recommendations (<b-link class="citation" data-cites="dave-design" href="#dave-design" >[@dave-design]</b-link>)</caption>
<thead>
<tr class="header">
<th style="text-align: center;">Advice</th>
</tr>
</thead>
<tbody>
<tr class="odd">
<td style="text-align: center;">You should name one file like the package</td>
</tr>
<tr class="even">
<td style="text-align: center;">No more than 600 lines per file</td>
</tr>
<tr class="odd">
<td style="text-align: center;">One file = One responsibility</td>
</tr>
</tbody>
</table>
<figure>
<b-img :src="require('@/assets/images/advice_sources.png')" alt="Source file recommendations[fig:Source-file-advices]"  fluid thumbnail class="img-book"></b-img><figcaption aria-hidden="true">Source file recommendations<span id="fig:Source-file-advices" label="fig:Source-file-advices">[fig:Source-file-advices]</span></figcaption>
</figure>
<ul>
<li><p><strong>One file should be named like the package :</strong> if you have multiple files in your package it’s better to name a file like the package. For instance, in figure <a href="#fig:Source-file-advices" data-reference-type="ref" data-reference="fig:Source-file-advices">2</a> you can see that we have two packages: fruit and bike. In the fruit package, we have a fruit.go, and in the bike package, we have a bike.go source file. Those files can support shared types, interfaces, and constants common to all fruits (or bikes).</p></li>
<li><p><strong>No more than 600 lines per file :</strong> this advice will improve your program/package readability. Files should be short (but not too short); it will make the life of maintainers easier (scrolling over long files can be boring). Note that this limit is arbitrary; you can, of course, adapt it to your standards.</p></li>
<li><p><strong>One file = One responsibility :</strong> imagine that you are part of the go developers team, and you have been assigned to the correction of a nasty bug. On the GitHub issue, the user is complaining about the way cookies are handled by the HTTP client. You will have to find where the cookies are managed. With no surprise, cookies are managed in the file <strong>net/http/cookie.go</strong>. This naming convention allows developers to locate source code responsibilities easily.</p></li>
</ul>
<div id="error-handling" class="anchor"></div>
<h1 data-number="6"><span class="header-section-number">6</span> Error Handling <a href="#error-handling"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>Errors and problems are part of the programming game. Your program has to handle all errors that might happen. As a programmer, you must think about the worst. Ask yourself what could go wrong in that line? What techniques could employ an evil user to make your program fail?</p>
<table>
<caption>Error Handling recommendations</caption>
<thead>
<tr class="header">
<th style="text-align: center;">Advice</th>
</tr>
</thead>
<tbody>
<tr class="odd">
<td style="text-align: center;">Always add context to errors</td>
</tr>
<tr class="even">
<td style="text-align: center;">Never ignore errors</td>
</tr>
<tr class="odd">
<td style="text-align: center;">Use fatal errors carefully</td>
</tr>
<tr class="even">
<td style="text-align: center;">Create fault-tolerant programs</td>
</tr>
</tbody>
</table>
<ul>
<li><strong>Always add context to errors</strong> : when creating errors, you should give enough information to users (but also to the team that will operate maintenance on your application). Errors without context are harder to understand, and finding their origin in sources can be challenging.</li>
</ul>
<!-- -->
<pre v-highlightjs><code class="go" v-pre >func main() {
    err := foo(&quot;test&quot;)
    if err != nil {
        fmt.Println(err)
    }
}

func foo(bar string) error {
    err := baz()
    if err != nil {
        return err
    }
    return nil
}

func baz() error {
    return corge()
}

func corge() error {
    _, err := ioutil.ReadFile(&quot;/my/imagination.go&quot;)
    if err != nil {
        return err
    }
    return nil
}

func looping() ([]byte, error) {
    return ioutil.ReadFile(&quot;/my/imagination.go&quot;)
}</code></pre>
<p>In this small example, we have created three function <span v-highlightjs><code class="go" v-pre style="display: inline">foo</code></span>, <span v-highlightjs><code class="go" v-pre style="display: inline">baz</code></span>, <span v-highlightjs><code class="go" v-pre style="display: inline">corge</code></span> and <span v-highlightjs><code class="go" v-pre style="display: inline">looping</code></span>. In the main function, we are calling <span v-highlightjs><code class="go" v-pre style="display: inline">foo</code></span>. This function will call <span v-highlightjs><code class="go" v-pre style="display: inline">baz.Baz</code></span> will call <span v-highlightjs><code class="go" v-pre style="display: inline">corge</code></span> and <span v-highlightjs><code class="go" v-pre style="display: inline">corge</code></span> will finally try to open a file (that doesn’t exist).</p>
<p>When we execute the program, we get the following output :</p>
<pre v-highlightjs><code class="go" v-pre >open /my/imagination.go: no such file or directory</code></pre>
<p>Where does the error come from? Does it come from the function <strong>corge</strong> ? Does it come from the function <span v-highlightjs><code class="go" v-pre style="display: inline">looping</code></span> ? If you want to know, you will have to follow the execution path mentally (to discover finally that <span v-highlightjs><code class="go" v-pre style="display: inline">looping</code></span> is never called, and thus the error comes from <strong>corge</strong>.</p>
<p>This exercise is hard in this example; it can become a nightmare for bigger applications of packages with hundreds of files.</p>
<p>The solution? Use the standard error package that allow you to wrap errors. (put another error into an error) <a href="#fn2" class="footnote-ref" id="fnref2" role="doc-noteref"><sup>2</sup></a>:</p>
<pre v-highlightjs><code class="go" v-pre >// recommendation/errors/better/main.go
package main

import (
    &quot;fmt&quot;
    &quot;io/ioutil&quot;
)

func main() {
    err := foo(&quot;test&quot;)
    if err != nil {
        fmt.Println(err)
    }
}

func foo(bar string) error {
    err := baz()
    if err != nil {
        return fmt.Errorf(&quot;error while calling baz: %w&quot;, err)
    }
    return nil
}

func baz() error {
    return corge()
}

func corge() error {
    _, err := ioutil.ReadFile(&quot;/my/imagination.go&quot;)
    if err != nil {
        return fmt.Errorf(&quot;error while reading file: %w&quot;, err)
    }
    return nil
}

func looping() ([]byte, error) {
    return ioutil.ReadFile(&quot;/my/imagination.go&quot;)
}</code></pre>
<p>We simply use <span v-highlightjs><code class="go" v-pre style="display: inline">fmt.Errorf</code></span> with the formatting verb <span v-highlightjs><code class="go" v-pre style="display: inline">%w</code></span> that will wrap the error. With this simple addition, the output of our program is now :</p>
<pre v-highlightjs><code class="go" v-pre >error while calling baz: error while reading file: open /my/imagination.go: no such file or directory</code></pre>
<p>You can see that the errors are clearer, and the localization of the failure immediate.</p>
<ul>
<li><p><strong>Never ignore errors</strong>. It’s maybe obvious, but still, many developers are making this mistake. Errors that arise should be handled :</p>
<ul>
<li><p>returned to the caller</p></li>
<li><p>or treated (your code implement some kind of auto-correction mechanism)</p></li>
</ul></li>
<li><p><strong>Use fatal errors carefully.</strong> When you make a call to log.Fatal[f] you are implicitly forcing your program to exit very abruptly (with an <span v-highlightjs><code class="go" v-pre style="display: inline">os.Exit(1)</code></span>).“The program terminates immediately; deferred functions are not run.” (os/proc.go). Deferred functions are often used for cleaning logic (for instance closing file descriptors). As a consequence, not running them is not optimal.</p></li>
</ul>
<!-- -->
<pre v-highlightjs><code class="go" v-pre >// standard log package.
// Fatal is equivalent to Print() followed by a call to os.Exit(1).
func Fatal(v ...interface{}) {
    std.Output(2, fmt.Sprint(v...))
    os.Exit(1)
}</code></pre>
<ul>
<li><strong>Try to create fault-tolerant programs</strong>. The term “fault tolerance” is used a lot by hardware engineers. Most hardware components are designed to handle failures graciously and to recover from them. Software engineers should also build their programs to tolerate errors. A program should serve its purpose despite failures (which can be transient or permanent).</li>
</ul>
<div id="if-an-error-occurs-examine-it-to-determine-if-its-recoverable-or-not." class="anchor"></div>
<h2 data-number="6.1"><span class="header-section-number">6.1</span> If an error occurs, examine it to determine if it’s recoverable or not. <a href="#if-an-error-occurs-examine-it-to-determine-if-its-recoverable-or-not."><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>For instance, you are building a program that makes a call to a webservice. During the execution of your program, the call failed. The source of failure is the network (your server has been disconnected from the internet). This error is recoverable, meaning that you can recover from the error because the network will become available again sometime.</p>
<p>If the call to your webservice successfully pass through the network but returned an http 301 error (“Moved Permanently”) the error is not recoverable. You had made a mistake when you defined the URL of the webservice Or your webservice provider has changed something without warning you. Human intervention will be necessary.</p>
<ul>
<li><strong>Implement a fallback option.</strong></li>
</ul>
<p>A fallback option is “is a contingency option to be taken if the preferred choice is unavailable” (Wikipedia). In our program, for instance, the network call is not possible or has returned an error. We should think about options.</p>
<p>Options will not be the same if the error is recoverable or not.</p>
<p>If you experienced a network failure, instead of directly returning the error, you could implement a <strong>retry</strong> mechanism. You will retry to contact the webservice a configurable number of times.</p>
<div id="methods-and-functions" class="anchor"></div>
<BuyCopyInvite></BuyCopyInvite>
<h1 data-number="7"><span class="header-section-number">7</span> Methods and functions <a href="#methods-and-functions"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>Functions and methods are everywhere inside a program. A syntactically correct function (i.e., the program compiles) might not be stylistically good. We want here to introduce some recommendations related to function writing. In a word, how to write functions with style.</p>
<table>
<caption>Methods and functions recommendations</caption>
<thead>
<tr class="header">
<th style="text-align: center;">Advice</th>
</tr>
</thead>
<tbody>
<tr class="odd">
<td style="text-align: center;">One function has one goal</td>
</tr>
<tr class="even">
<td style="text-align: center;">Simple names</td>
</tr>
<tr class="odd">
<td style="text-align: center;">Limited length (100 lines maximum)</td>
</tr>
<tr class="even">
<td style="text-align: center;">Reduce cyclomatic complexity</td>
</tr>
<tr class="odd">
<td style="text-align: center;">Reduce the number of nesting levels</td>
</tr>
</tbody>
</table>
<div id="one-function-has-one-goal." class="anchor"></div>
<h2 data-number="7.1"><span class="header-section-number">7.1</span> One function has one goal. <a href="#one-function-has-one-goal."><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>A function is a named procedure (or routine) that will perform a <strong>specific</strong> task. It can have input parameters and also output parameters. The important term here is “specific”. The function (or method) performs a single task, not multiple. It has a single goal.</p>
<p>A great function is a function that does one thing, and that does it perfectly well. In math, for instance, the exponential function will compute the value of exp(x) for every x real value.</p>
<p>This function should have only one goal, which is simple to understand. The function will not compute at the same time the exponential and the logarithmic value of x. Instead, we have two functions, the exponential function, and the logarithm function.</p>
<p>Here is a counter-example :</p>
<pre v-highlightjs><code class="go" v-pre >type User struct {
    //...
}

func (u *User) saveAndAuthorize error {
    //...
    return nil
}</code></pre>
<p>The method <span v-highlightjs><code class="go" v-pre style="display: inline">saveAndAuthorize</code></span> perform 2 tasks :</p>
<ul>
<li><p>save the user</p></li>
<li><p><strong>and</strong> authorize it.</p></li>
</ul>
<p>Two different tasks require different abilities (writing to a database, reading from a database, check an access token validity ...). This program will compile, but it will be difficult to test. The error returned can be provoked by the failure of the data layer but also from the security layer of the app.</p>
<p>A solution could be to split the function into two different ones : <span v-highlightjs><code class="go" v-pre style="display: inline">create</code></span> and <span v-highlightjs><code class="go" v-pre style="display: inline">authorize</code></span>.</p>
<pre v-highlightjs><code class="go" v-pre >func (u *User) create error {
    //..
    return nil
}

func (user *User) authorize error {
    //...
    return nil
}</code></pre>
<div id="simple-names" class="anchor"></div>
<h2 data-number="7.2"><span class="header-section-number">7.2</span> Simple Names <a href="#simple-names"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<ul>
<li>Do not repeat the name of the receiver type in the method name</li>
</ul>
<p>For instance :</p>
<pre v-highlightjs><code class="go" v-pre >func (u *User) saveUser() error {

    return nil
}

func (u *User) authorizeUser() error {

    return nil
}</code></pre>
<p>We can rename those two functions:</p>
<pre v-highlightjs><code class="go" v-pre >func (u *User) save() error {

    return nil
}

func (u *User) authorize() error {

    return nil
}</code></pre>
<p>We reduce the function name’s size by removing the type name user. Remember to always <strong>think in the perspective of the caller</strong> of your package. Let’s compare those two snippets :</p>
<pre v-highlightjs><code class="go" v-pre >user := user.NewUser()
err := user.saveUser()
if err != nil {
    //..
}

user := user.New()
err := user.save()
if err != nil {
    //..
}</code></pre>
<p>The second one is is much simpler than the first one. The first one is composed of 65 characters, whereas the second one is composed of 57 characters (space included).</p>
<div id="a-limited-number-of-lines" class="anchor"></div>
<h2 data-number="7.3"><span class="header-section-number">7.3</span> A limited number of lines <a href="#a-limited-number-of-lines"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>A function should pursue one goal (see the previous section), and it should be small. When you increase the number of lines in a function, you also increase the time and cognitive effort to read it and then understand it.</p>
<p>For instance, here is the function <span v-highlightjs><code class="go" v-pre style="display: inline">Pop</code></span> from the package <span v-highlightjs><code class="go" v-pre style="display: inline">heap</code></span> :</p>
<pre v-highlightjs><code class="go" v-pre >func Pop(h Interface) interface{} {
    n := h.Len() - 1
    h.Swap(0, n)
    down(h, 0, n)
    return h.Pop()
}</code></pre>
<p>The number of lines of this function is just 4. It makes it pretty easy and to understand. Compare this function from the <span v-highlightjs><code class="go" v-pre style="display: inline">ascii85</code></span> package :</p>
<pre v-highlightjs><code class="go" v-pre >func (d *decoder) Read(p []byte) (n int, err error) {
    if len(p) == 0 {
        return 0, nil
    }
    if d.err != nil {
        return 0, d.err
    }

    for {
        // Copy leftover output from last decode.
        if len(d.out) &gt; 0 {
            n = copy(p, d.out)
            d.out = d.out[n:]
            return
        }

        // Decode leftover input from last read.
        var nn, nsrc, ndst int
        if d.nbuf &gt; 0 {
            ndst, nsrc, d.err = Decode(d.outbuf[0:], d.buf[0:d.nbuf], d.readErr != nil)
            if ndst &gt; 0 {
                d.out = d.outbuf[0:ndst]
                d.nbuf = copy(d.buf[0:], d.buf[nsrc:d.nbuf])
                continue // copy out and return
            }
            if ndst == 0 &amp;&amp; d.err == nil {
                // Special case: input buffer is mostly filled with non-data bytes.
                // Filter out such bytes to make room for more input.
                off := 0
                for i := 0; i &lt; d.nbuf; i++ {
                    if d.buf[i] &gt; &#39; &#39; {
                        d.buf[off] = d.buf[i]
                        off++
                    }
                }
                d.nbuf = off
            }
        }

        // Out of input, out of decoded output. Check errors.
        if d.err != nil {
            return 0, d.err
        }
        if d.readErr != nil {
            d.err = d.readErr
            return 0, d.err
        }

        // Read more data.
        nn, d.readErr = d.r.Read(d.buf[d.nbuf:])
        d.nbuf += nn
    }
}</code></pre>
<p>This function has 50 lines.</p>
<p>What is the good number of lines. In my opinion, a good function should not have more than 30 lines. You should be able to display a function in your IDE (code editor) window without the need to scroll down. On my IDE, I can read just 38 lines at once.</p>
<div id="reduced-cyclomatic-complexity" class="anchor"></div>
<h2 data-number="7.4"><span class="header-section-number">7.4</span> Reduced cyclomatic complexity <a href="#reduced-cyclomatic-complexity"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>The number of lines inside a function is not sufficient to judge its simplicity. In 1976 Thomal J.McCabe developed an interesting notion called “cyclomatic complexity”<b-link class="citation" data-cites="mccabe1976complexity" href="#mccabe1976complexity" >[@mccabe1976complexity]</b-link>. The idea is that we can use the graph theory to detect complexity in programs.</p>
<p>We can compose a function of one or more conditional statements. For instance, we can have several if statements. Let’s take a look at the following example.</p>
<pre v-highlightjs><code class="go" v-pre >package main

import &quot;fmt&quot;

func main() {
    fmt.Println(foo(2, 3))
    fmt.Println(foo(11, 0))
    fmt.Println(foo(8, 12))
}

func foo(a, b int) int {
    if a &gt; 10 {
        return a
    }
    if b &gt; 10 {
        return b
    }
    return b - a
}</code></pre>
<p>In the function foo we have two input parameters, <span v-highlightjs><code class="go" v-pre style="display: inline">a</code></span> and <span v-highlightjs><code class="go" v-pre style="display: inline">b</code></span>. In the function body, we can observe two if statements. We have two conditions (we compare <span v-highlightjs><code class="go" v-pre style="display: inline">a</code></span> and <span v-highlightjs><code class="go" v-pre style="display: inline">b</code></span> to specific numbers).</p>
<p>When we run our function, we can imagine three logical “paths” :</p>
<ul>
<li><p>The first condition is true. The second condition is not evaluated. The returned value is <span v-highlightjs><code class="go" v-pre style="display: inline">a</code></span>.</p></li>
<li><p>The first condition is false, the second is true. The returned value is <span v-highlightjs><code class="go" v-pre style="display: inline">b</code></span>.</p></li>
<li><p>The first condition is false, and the second is also false. The return value is <span v-highlightjs><code class="go" v-pre style="display: inline">b-a</code></span></p></li>
</ul>
<p>We have three paths. The more paths you have, the more effort you need to understand the function.</p>
<p>The more paths you got, the more unit tests you have to develop to cover all the possible cases.</p>
<div id="computation-of-the-cyclomatic-number" class="anchor"></div>
<h4 data-number="7.4.0.1"><span class="header-section-number">7.4.0.1</span> Computation of the cyclomatic number <a href="#computation-of-the-cyclomatic-number"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h4>
<p>This section is not mandatory to understand the concept of cyclomatic complexity reduction. But, you might find it interesting to know the reasoning behind “cyclomatic complexity”.</p>
<p>First, every program can be seen as a graph. A graph is composed of nodes and edges. For instance on the figure <a href="#fig:Edges-and-nodes" data-reference-type="ref" data-reference="fig:Edges-and-nodes">3</a> you can see a graph. A graph is composed of nodes and edges. Each node will represent a group of code. The edges will represent the flow of control in the program.</p>
<figure>
<b-img :src="require('@/assets/images/edge_nodes.png')" alt="Edges and nodes in a directed graph[fig:Edges-and-nodes]"  fluid thumbnail class="img-book"></b-img><figcaption aria-hidden="true">Edges and nodes in a directed graph<span id="fig:Edges-and-nodes" label="fig:Edges-and-nodes">[fig:Edges-and-nodes]</span></figcaption>
</figure>
<p>Let’s take a simple example. We have the following function :</p>
<pre v-highlightjs><code class="go" v-pre >func bar(a int) {
    fmt.Println(&quot;start of function&quot;)
    if a &gt; 2 {
        fmt.Println(&quot;a is greater than 2&quot;)
        return
    }
    fmt.Println(&quot;you got&quot;)
    fmt.Println(&quot;bad luck&quot;)
}</code></pre>
<figure>
<b-img :src="require('@/assets/images/cyclo_complexity.png')" alt="Graph and correcponding function"  fluid thumbnail class="img-book"></b-img><figcaption aria-hidden="true">Graph and correcponding function</figcaption>
</figure>
<p>We have represented here the program with a set of nodes and edges. Each block of code is represented by a node. This is very important here. We do not add a node for every statement but for every group of executed statements following a decision rule. Here we have to call to <span v-highlightjs><code class="go" v-pre style="display: inline">fmt.Println</code></span> that are represented by a single node.</p>
<p>Let’s count the nodes and the edges on that graph :</p>
<ul>
<li><p>Four nodes</p></li>
<li><p>Three edges</p></li>
</ul>
<p>The formula to get the cyclomatic complexity is (for a function) :</p>
<pre v-highlightjs><code class="go" v-pre >V(G)= # of edges- # of nodes + 2</code></pre>
<p>The cyclomatic number is denoted <span v-highlightjs><code class="go" v-pre style="display: inline">V(G)</code></span>.</p>
<pre v-highlightjs><code class="go" v-pre >V(G)= 3 - 3 + 2 = 2</code></pre>
<p>Here the cyclomatic number is equal to 2 it means that our program defines two linearly independent paths. When this number grows, your function grows in complexity :</p>
<ul>
<li><p>More paths mean more unit tests to develop to cover your code fully</p></li>
<li><p>More paths mean more brainpower needed for your colleagues to understand your code.</p></li>
</ul>
<p><strong>Some important facts about the cyclomatic number (<b-link class="citation" data-cites="mccabe1976complexity" href="#mccabe1976complexity" >[@mccabe1976complexity]</b-link>) :</strong></p>
<ul>
<li><p>This number depends only on the “decision structure of the graph”.</p></li>
<li><p>It is not affected when you add a functional statement to your code.</p></li>
<li><p>If you insert a new edge in the graph, then you will increase the cyclomatic number by 1</p></li>
</ul>
<div id="halstead-metrics" class="anchor"></div>
<h2 data-number="7.5"><span class="header-section-number">7.5</span> Halstead metrics <a href="#halstead-metrics"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>I want to devote this section to the so-called “Halstead Complexity metrics”. Maurice Howard Halstead was one of the pioneers of computer science. He has developed in 1977 metrics to assess a program’s complexity with metrics derived from its source code.</p>
<ul>
<li><p>The program <strong>vocabulary</strong></p></li>
<li><p>The program <strong>length</strong></p></li>
<li><p>The <strong>effort</strong> required to write the program</p></li>
<li><p>The <strong>difficulty</strong> that will be needed to read and understand the program</p></li>
</ul>
<p>Halstead metrics are based on two concepts. Operators and operands. A program is composed of tokens. Those tokens are reserved words, variables names, brackets, curly brackets... etc. Those tokens can be classed into two main categories :</p>
<ol type="1">
<li><p><strong>Operators :</strong></p>
<ol type="1">
<li><p>All reserved words. (func, const, var,...)</p></li>
<li><p>Pairs of brackets, pairs of curly brackets ({},())</p></li>
<li><p>All the comparison and logical operators ( &gt;,&lt;,&amp;&amp;,||,...)</p></li>
</ol></li>
<li><p><strong>Operands :</strong></p>
<ol type="1">
<li><p>Identifiers (a, myVariableName, myConstantName, myFunction,...)</p></li>
<li><p>Constant values (“this is a string”, 3, 22,...)</p></li>
<li><p>Type specification (int, bool,...)</p></li>
</ol></li>
</ol>
<p>From those two definitions, we can extract some base numbers (we will use that to compute Halstead metrics) :</p>
<ul>
<li><p><span class="math inline">n_{1}</span> the number of distinct operators</p></li>
<li><p><span class="math inline">n_{2}</span> the number of distinct operands</p></li>
<li><p><span class="math inline">N_{1}</span> the total number of operators</p></li>
<li><p><span class="math inline">N_{2}</span> the total number of operands</p></li>
</ul>
<p>Let’s take an example program to extract those four numbers :</p>
<pre v-highlightjs><code class="go" v-pre >func bar(a int) {
    fmt.Println(&quot;start of function&quot;)
    if a &gt; 2 {
        fmt.Println(&quot;a is greater than 2&quot;)
        return
    }
    fmt.Println(&quot;you got&quot;)
    fmt.Println(&quot;bad luck&quot;)
}</code></pre>
<p><span class="math inline">n_{1}</span> is the number of distinct operators</p>
<ul>
<li><p>func, bar, (), {}, if, &gt;, return</p>
<ul>
<li>We have seven distinct operators here</li>
</ul></li>
</ul>
<p><span class="math inline">n_{2}</span> is the number of distinct operands</p>
<ul>
<li><p>int, a, fmt.Println,start of function, 2, a is greater than 2,you got,bad luck</p>
<ul>
<li>We have nine distinct operands</li>
</ul></li>
</ul>
<p><span class="math inline">N_{1}</span> is the total number of operators</p>
<ul>
<li><p>func, bar, (), () ,() ,() ,() ,{},{}, if, &gt;, return</p>
<ul>
<li>We have 12</li>
</ul></li>
</ul>
<p><span class="math inline">N_{2}</span> is the total number of operands</p>
<ul>
<li><p>a, a,start of function, 2, a is greater than 2,you got,bad luck, fmt.Println, fmt.Println,fmt.Println, fmt.Println, int</p>
<ul>
<li>We have a total of 12 operands</li>
</ul></li>
</ul>
<p>Let’s compute the Halstead metrics for our program :</p>
<dl>
<dt>vocabulary</dt>
<dd><p><span class="math inline">n=n_{1}+n_{2}=8+9=17</span></p>
</dd>
<dt>length</dt>
<dd><p><span class="math inline">N=N_{1}+N_{2}=12+12=24</span></p>
</dd>
<dt>difficulty</dt>
<dd><p><span class="math inline">\frac{n_{1}}{2}\times\frac{N_{2}}{n_{2}}=5.33</span></p>
</dd>
<dt>volume</dt>
<dd><p><span class="math inline">\text{length}\times log_{2}(\text{vocabulary})=98.10</span></p>
</dd>
<dt>effort</dt>
<dd><p><span class="math inline">\text{difficulty}\times\text{volume = 523.20}</span></p>
</dd>
</dl>
<p>Those formulas are worth some explanations.</p>
<ul>
<li><p>The <strong>vocabulary</strong> of a program is just like the vocabulary of some essay. For an English essay, we can say that an author’s vocabulary is the total number of different words. For a program, this is the addition of the total number of distinct operators and operands. If the program use only reserved words and a very limited number of identifiers, its vocabulary will below. On the contrary, if your program uses many identifiers, the vocabulary will increase.</p></li>
<li><p>The <strong>length</strong> of a program is the total number of operators and operands used. Here we are not counting distinct tokens but the total number of tokens.</p></li>
<li><p>The <strong>difficulty</strong> is here to give an idea about the amount of time needed to write the program, and to read it. This metrics is equal to half the number of operands multiplied by the quotient between the total number of operators and the distinct number of operands. If your program uses a limited number of operands, the difficulty will be reduced. If the total number of operands increases, the difficulty will also increase (more comparisons, more identifiers, more types to handle and remember).</p></li>
<li><p>The effort metric can then be used to compute the time necessary to write the program.</p></li>
</ul>
<p>Time to write the program : E/18 (in seconds) : in our example : 29 seconds (523,20 / 18).</p>
<p>Halstead also details an estimated number of bugs! <span class="math display">B=\frac{E^{2/3}}{3000}</span></p>
<div id="comment" class="anchor"></div>
<h4 data-number="7.5.0.1"><span class="header-section-number">7.5.0.1</span> Comment <a href="#comment"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h4>
<ul>
<li><p>Those metrics are interesting, but we should take them cautiously.</p></li>
<li><p>However, they highlight that the more code we write, the more complex our program will become.</p></li>
<li><p>A simple, short, and stupid code is better than an over-engineered solution.</p></li>
</ul>
<div id="reduce-the-number-of-nesting-levels" class="anchor"></div>
<h2 data-number="7.6"><span class="header-section-number">7.6</span> Reduce the number of nesting levels <a href="#reduce-the-number-of-nesting-levels"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>This advice has to be linked with the previous section. When you write a program, you can introduce nested statements, i.e., statements executed in a specific branch. Let’s take an example. Here, we have a dummy function with a first condition that creates two branches. In the first branch, visualize we have introduced another conditional statement (if b &lt; 2 ) that will also create two branches.</p>
<pre v-highlightjs><code class="go" v-pre >// recommendation/nesting/main.go 
//...

func nested(a, b int) {
    if a &gt; 1 {
        if b &lt; 2 { // nested condition
            fmt.Println(&quot;action 1&quot;)
        } else {
            fmt.Println(&quot;action 2&quot;)
        }
    } else {
        fmt.Println(&quot;action 3&quot;)
    }
    fmt.Println(&quot;action 4&quot;)
}</code></pre>
<p>You can vizualize the in the sequence diagram (figure <a href="#fig:Nested-Statement-sequence" data-reference-type="ref" data-reference="fig:Nested-Statement-sequence">4</a>) the branches.</p>
<figure>
<b-img :src="require('@/assets/images/nested_stetements.png')" alt="Nested Statement sequence diagram[fig:Nested-Statement-sequence]"  fluid thumbnail class="img-book"></b-img><figcaption aria-hidden="true">Nested Statement sequence diagram<span id="fig:Nested-Statement-sequence" label="fig:Nested-Statement-sequence">[fig:Nested-Statement-sequence]</span></figcaption>
</figure>
<p>We can add another level of nesting :</p>
<pre v-highlightjs><code class="go" v-pre >// recommendation/nesting/main.go 
//...

func nested2(a, b int) {
    if a &gt; 1 {
        if b &lt; 2 { // nested condition
            if a &gt; 100 {
                fmt.Println(&quot;action 1&quot;)
            } else {
                fmt.Println(&quot;action 2&quot;)
            }
        } else {
            fmt.Println(&quot;action 3&quot;)
        }
    } else {
        fmt.Println(&quot;action 4&quot;)
    }
    fmt.Println(&quot;action 5&quot;)
}</code></pre>
<p>On the figure <a href="#fig:Nested-Statements-2" data-reference-type="ref" data-reference="fig:Nested-Statements-2">5</a> you can see the impact on the sequence diagram of this new nesting level.</p>
<figure>
<b-img :src="require('@/assets/images/nested_statement_2.png')" alt="Nested Statements (version 2) sequence diagram[fig:Nested-Statements-2]"  fluid thumbnail class="img-book"></b-img><figcaption aria-hidden="true">Nested Statements (version 2) sequence diagram<span id="fig:Nested-Statements-2" label="fig:Nested-Statements-2">[fig:Nested-Statements-2]</span></figcaption>
</figure>
<p>The more nesting levels you got, the more complicated your code will become. One general piece of advice would be to limit nesting levels. If you find yourself in a situation where you cannot avoid it, you should instead create another function to support that complexity :</p>
<pre v-highlightjs><code class="go" v-pre >// recommendation/nesting/main.go 
//...

func nested3(a, b int) {
    if a &gt; 1 {
        subFct1(a, b)
    } else {
        fmt.Println(&quot;action 4&quot;)
    }
    fmt.Println(&quot;action 5&quot;)
}

func subFct1(a, b int) {
    if b &lt; 2 { // nested condition
        if a &gt; 100 {
            fmt.Println(&quot;action 1&quot;)
        } else {
            fmt.Println(&quot;action 2&quot;)
        }
    } else {
        fmt.Println(&quot;action 3&quot;)
    }
}</code></pre>
<div id="key-takeaways" class="anchor"></div>
<h1 data-number="8"><span class="header-section-number">8</span> Key takeaways <a href="#key-takeaways"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<ul>
<li><p>Package names</p>
<ul>
<li><p>Short: no more than one word</p></li>
<li><p>No plural</p></li>
<li><p>Lower case</p></li>
<li><p>Informative about the service it gives</p></li>
<li><p>Avoid utilities/models packages</p></li>
</ul></li>
<li><p>Interfaces</p>
<ul>
<li><p>Use interfaces as function/method arguments &amp; as field types</p></li>
<li><p>Small interfaces are better</p></li>
</ul></li>
<li><p>Source files</p>
<ul>
<li><p>One file should be named like the package</p></li>
<li><p>No more than 600 lines per file</p></li>
<li><p>One file = One responsibility</p></li>
</ul></li>
<li><p>Error Handling</p>
<ul>
<li><p>Always add context to errors</p></li>
<li><p>Never ignore errors</p></li>
<li><p>Use fatal errors carefully</p></li>
<li><p>Create fault-tolerant programs</p></li>
</ul></li>
<li><p>Methods/functions</p>
<ul>
<li><p>One function has one goal</p></li>
<li><p>Simple names</p></li>
<li><p>Limited length (100 lines maximum)</p></li>
<li><p>Reduce cyclomatic complexity</p></li>
<li><p>Reduce the number of nesting levels</p></li>
</ul></li>
</ul>
<section class="footnotes" role="doc-endnotes">
<hr />
<ol>
<li id="fn1" role="doc-endnote"><p>Totally arbitrary number<a href="#fnref1" class="footnote-back" role="doc-backlink">↩︎</a></p></li>
<li id="fn2" role="doc-endnote"><p>Note that this package was originally developed by Dave Cheney https://github.com/pkg/errors , it’s now part of the standard library<a href="#fnref2" class="footnote-back" role="doc-backlink">↩︎</a></p></li>
</ol>
</section>

                    <!-- END CONTENT -->
                    <!-- Bibliography -->
                    <h1>Bibliography</h1>
                    <ChapterBibliography chapter-id="Chap41DesignRecommendations"></ChapterBibliography>
					<!-- Next / Previous -->
					<b-row class="ml-1 mr-1 ">
						
							<b-col class="text-center border mr-1 p-2" >
								<router-link :to="{name:'Chap40UpgradingOrDowngradingGo'}">
									<p><u><small>Previous</small></u></p>
									<p><small>Upgrading or Downgrading Go</small></p>
								</router-link>
							</b-col>
						
						
							<b-col class="text-center border p-1 ">
								<router-link :to="{name:'Chap42Cheatsheet'}">
									<p><u><small>Next</small></u></p>
									<p><small>Cheatsheet</small></p>
								</router-link>
							</b-col>
						
					</b-row>
					<b-row class="mt-1 ml-1 mr-1">
						<b-col class="text-center border p-1 ">
							<b-link :to="{name:'Home'}" >Table of contents</b-link>
						</b-col>
					</b-row>
          			<FeedbackInvite></FeedbackInvite>
					<NewsletterInput></NewsletterInput>
					<Footer></Footer>
                </b-col>
				<b-col ></b-col>
            </b-row>
        </div>
    </div>
</template>

<script>
import TOCChapter from "@/components/toc/TocChapter";
import ChapterBibliography from "@/components/ChapterBibliography";
import NavBar from "@/components/NavBar";
import { BIconLink45deg } from 'bootstrap-vue'
import Footer from "@/components/Footer";
import ChapterHeading from "@/components/ChapterHeading";
import BuyCopyInvite from "@/components/BuyCopyInvite";
import NewsletterInput from "@/components/NewsletterInput";
import FeedbackInvite from "@/components/FeedbackInvite";

const title = "Design Recommendations - Practical Go Lessons"
const description = "Go design recommendations, how to build beautiful programs"

export default {
  name: "Chap41DesignRecommendations",
  components: {FeedbackInvite,BuyCopyInvite,NewsletterInput,ChapterHeading, ChapterBibliography,TOCChapter,NavBar,BIconLink45deg, Footer},

mounted() {
    const mathElements = window.document.getElementsByClassName("math");
    const macros = [];
    for (var i = 0; i < mathElements.length; i++) {
      const texText = mathElements[i].firstChild;
      if (mathElements[i].tagName === "SPAN") {
 		window.katex.render(texText.data, mathElements[i], {
          displayMode: mathElements[i].classList.contains('display'),
          throwOnError: true,
          macros: macros,
          fleqn: false
        });
      }
    }
  },

  created() {
    window.scrollTo(0,0);
  },data () {return {publicPath: process.env.BASE_URL}},
  metaInfo: {
      title: title,
      htmlAttrs: {
        lang: 'en',
      },
      meta: [
        { charset: 'utf-8' },
        { name: 'description', content: description },
        { name: 'robots', content: "index, follow" },
        { property: 'og:locale', content: process.env.VUE_APP_SITE_LOCALE_META },
        { property: 'og:type', content: "website" },
        { property: 'og:title', content: title},
        { property: 'og:description', content: description },
        { property: 'og:url', content: window.location.href },
        { property: 'og:site_name', content: 'Practical Go Lessons' },
        { property: 'twitter:card', content: "summary_large_image" },
        { property: 'twitter:creator', content: process.env.VUE_APP_TWITTER_USERNAME }
      ],
      link : [
        { rel : "canonical", href : window.location.href}
      ]

    }
}
</script>

<style scoped>

</style>
